<template>
    <div>
        <CRow>
            <CCol col="12">
                <CCard>
                    <CCardHeader>Şüpheli Hareket Oluştur</CCardHeader>
                    <CCardBody>
                        <dl class="row" v-for="item in fields" :key="item.label">
                            <dt class="col-sm-3">{{ item.label }}</dt>
                            <dd class="col-sm-9">
                                {{ (item.getValue ? item.getValue(order) : order[item.field]) || '-' }}
                            </dd>
                        </dl>
                        <CForm>
                            <CTextarea
                                label="Not"
                                description="Siparişle ilgili şüpheli hareket açıklaması"
                                horizontal
                                :value.sync="message"
                            />
                        </CForm>
                    </CCardBody>
                    <CCardFooter>
                        <CButton
                            type="button"
                            size="sm"
                            color="primary"
                            v-on:click="save()"
                        >
                            <CIcon name="cil-check-circle"/> Kaydet
                        </CButton>
                    </CCardFooter>
                </CCard>
            </CCol>
        </CRow>
    </div>
</template>

<script>
import axios from 'axios';
import moment from 'moment';

import { getOrderStatus, getPaymentMethod } from '../../utils';

export default {
    name: 'SurveillanceCreate',

    data () {
        return {
            message: '',
            order: { },
            fields: [
                {
                    field: '',
                    getValue(data) { return `${data.firstname} ${data.lastname}` },
                    label: 'İsim',
                },
                {
                    field: 'email',
                    label: 'E-Posta adresi',
                },
                {
                    field: 'phone',
                    label: 'Telefon numarası',
                },
                {
                    label: 'Sipariş durumu',
                    getValue(data) { return getOrderStatus(data.status) }
                },
                {
                    label: 'Ödeme yöntemi',
                    getValue(data) { return getPaymentMethod(data.paymentMethod) }
                },
                {
                    field: 'paymesReference',
                    label: 'Paymes referans numarası',
                },
                {
                    field: 'price',
                    label: 'Toplam Tutar',
                },
                {
                    label: 'Adres',
                    getValue(data) {
                        return `${data.address} ${data.city}/${data.country}`;
                    }
                },
                {
                    label: 'Sipariş Tarihi',
                    getValue(data) {
                        return moment(data.date).format('LLL');
                    }
                },
            ],
        }
    },

    methods: {
        closeAlert() { this.alert.isOpen = false; },

        addSize() {
            this.product.sizes.push(this.newSize);
            this.newSize = {
                name: '',
                count: 0
            };
        },

        async save() {
            try {
                const response = await axios({
                    method: 'post',
                    url: '/surveillances',
                    data: {
                        orderId: this.order.id,
                        message: this.message,
                    }
                });

                if (response.status === 201) {
                    this.$router.push('/orders/list');
                }
            } catch (error) {
                console.log(error)
                this.alert = {
                    isOpen: true,
                    color: 'danger',
                    message: 'İşlem gerçekleştirilirken bir hata oluştu'
                };
            }
        },
    },

    async mounted() {
        const response = await axios({
            method: 'get',
            url: `/orders/${this.$route.query.orderId}`
        });

        this.order = response.data;
    }
}
</script>
